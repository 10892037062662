const validateRules = (
  password: string,
  newPassword: string,
  confirmNewPassword: string
) => {
  return [
    {
      name: 'confirmNewPasswordError',
      message: 'New password not match',
      rule: newPassword && newPassword !== confirmNewPassword,
    },
    {
      name: 'newPasswordError',
      message: 'New password should not be same as old password',
      rule: password && newPassword && password === newPassword,
    },
  ]
}

export default validateRules
