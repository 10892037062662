import React from 'react'
import clsx from 'clsx'
import {
  makeStyles,
  Drawer,
  AppBar,
  CssBaseline,
  Toolbar,
  Typography,
  Button,
  Grid,
  Paper,
  CircularProgress,
  IconButton,
} from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { orange } from '@material-ui/core/colors'
import ServerButton from '../component/ServerButton'
import ApplicationButton from '../component/ApplicationButton'

type ServerPageProps = {
  data: any
  setServerExpand: (value: boolean) => void
  setResetExpand: (value: boolean) => void
  setMessage: (value: string) => void
}

const ServerPage: React.FC<ServerPageProps> = ({
  data,
  setResetExpand,
  setServerExpand,
  setMessage,
}) => {
  const classes = useStyles()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [selectedServer, setSelectedServer] = React.useState<string>('')
  const [expand, setExpand] = React.useState(true)
  const [externalUrl, setExternalUrl] = React.useState('')
  const [token, setToken] = React.useState('')
  const [refreshToken, setRefreshToken] = React.useState('')

  const dataString = JSON.stringify(data)
  //list for supporting iframe login
  const newLoginSupportedList = ['SIT', 'UAT']
  const application = React.useMemo(() => {
    if (data) {
      return data.filter((e: any) => e?.server === selectedServer)?.[0]
        ?.applications
    }
  }, [dataString, selectedServer])

  // const onDialogClose = () => {
  //   window.opener = null
  //   window.open('', '_self', '')?.close()
  //   //window.close()
  // }

  React.useEffect(() => {
    //jump to new site
    if (externalUrl) {
      if (newLoginSupportedList.includes(selectedServer)) {
        const portal = document.getElementById('portal')
        const data = JSON.stringify({
          token,
          refreshToken,
          firstRender: true,
        })
        window.onmessage = function (e: any) {
          const messageData = e?.data
          if (
            typeof messageData === 'string' &&
            messageData?.charAt(0) === '{' &&
            messageData?.charAt(messageData?.length - 1) === '}'
          ) {
            const result = JSON.parse(messageData?.replace(/\\/g, ''))
            if (result?.status === 'render') {
              //@ts-ignore
              portal.contentWindow.postMessage(data, '*')
            }
            if (result?.status === 'injected') {
              const initialRoute = `${externalUrl.split('/externalLogin')[0]}/${
                result?.initialRoute
              }`
              window.parent.location.href = initialRoute
            }
          }
        }
      } else {
        window.parent.location.href = externalUrl
      }
    }
  }, [externalUrl])

  if (!data)
    return (
      <div className={classes.root}>
        <CssBaseline />
      </div>
    )
  const handleGoBack = () => {
    setServerExpand(false)
    setIsSubmitting(false)
    setSelectedServer('')
    setExpand(true)
    setExternalUrl('')
    setToken('')
    setRefreshToken('')
  }

  return (
    <Paper elevation={2} className={classes.root}>
      {isSubmitting && (
        <React.Fragment>
          <div className={classes.loadingLayer} />
          <CircularProgress className={classes.loadingIcon} size={50} />
        </React.Fragment>
      )}
      <CssBaseline />
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.serverBar}>
          <Typography variant="h6" noWrap>
            {expand ? 'Client List' : 'Application List'}
          </Typography>
          <IconButton onClick={handleGoBack}>
            <ArrowBack style={{ color: 'white' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: expand,
              [classes.drawerClose]: !expand,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: expand,
                [classes.drawerClose]: !expand,
              }),
            }}
          >
            <div className={classes.drawerContainer}>
              <Grid
                container
                className={clsx(classes.serverButtonContainer, {
                  [classes.serverButtonContainer__drawerClose]: !expand,
                })}
              >
                {data.map((e: any, index: number) => {
                  return (
                    <Grid key={index} xs={4} item>
                      <ServerButton
                        serverInfo={e}
                        setSelectedServer={setSelectedServer}
                        setExpand={setExpand}
                        setServerExpand={setServerExpand}
                        setResetExpand={setResetExpand}
                        setMessage={setMessage}
                        selectedServer={selectedServer}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </div>
          </Drawer>
          <main
            className={clsx(classes.content, {
              [classes.contentOpen]: expand,
              [classes.contentClose]: !expand,
            })}
          >
            <Grid
              container
              spacing={0}
              style={{
                alignContent: 'center',
              }}
            >
              {application?.map((e: any, index: number) => {
                return (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    key={index}
                    style={{ textAlign: 'center' }}
                  >
                    {e.useLocalStorage ? (
                      <ApplicationButton
                        applicationInfo={e}
                        type={'button'}
                        setIsSubmitting={setIsSubmitting}
                        setRefreshToken={setRefreshToken}
                        setToken={setToken}
                        setExternalUrl={setExternalUrl}
                        newLoginSupportedList={newLoginSupportedList}
                        selectedServer={selectedServer}
                        isAutoSubmit={application?.length === 1}
                      />
                    ) : (
                      //http form login
                      <form
                        action={e?.url}
                        method="PUT"
                        onSubmit={() => {
                          setTimeout(() => {
                            window.parent.location.href = e?.url
                          }, 1000)
                        }}
                      >
                        <input type="hidden" name="token" value={e?.token} />
                        <ApplicationButton
                          applicationInfo={e}
                          type={'submit'}
                          setIsSubmitting={setIsSubmitting}
                          // isAutoSubmit={application?.length === 1}
                        />
                      </form>
                    )}
                  </Grid>
                )
              })}
              <iframe
                src={externalUrl}
                //src="https://localhost:3002/externalLogin"
                id="portal"
                width={0}
                height={0}
                style={{ visibility: 'hidden' }}
              />
            </Grid>
          </main>
        </div>
        <div className={classes.dialogButtonWrapper}>
          <Button
            variant="contained"
            color="primary"
            className={classes.dialogButton}
            classes={{
              label: classes.dialogButtonText,
              root: classes.changePasswordButtonRoot,
            }}
            onClick={() => {
              setResetExpand(true)
              setServerExpand(false)
            }}
          >
            Change Password
          </Button>
        </div>
      </div>
    </Paper>
  )
}

export default ServerPage

// eslint-disable-next-line
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'inherit',
    background: '#035aaa',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    //display: 'flex',
    minHeight: 300,
  },
  // wrapper__drawerClose: {
  //   display: 'flex',
  //   height: 420,
  // },
  drawer: {
    width: '100%',
    flexShrink: 0,
  },
  drawerOpen: {
    position: 'inherit',
    //width: 500,
    // height: 300,
    transition: theme.transitions.create('hight', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    position: 'inherit',
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'hidden',
    //height: 250,
  },
  contentOpen: {
    width: 0,
    height: 0,
    overflowX: 'hidden',
    padding: 0,
    transition: theme.transitions.create('display', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentClose: {
    padding: `${theme.spacing(2.5)}px ${theme.spacing(2)}px`,
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowY: 'hidden',
    // width: 500,
    height: 130,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
  },
  serverBar: {
    minHeight: 50,
    display: 'flex',
    justifyContent: 'space-between',
  },
  serverWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  serverTitle: {
    fontSize: 14,
    color: 'rgb(103, 106, 108)',
    // color: props => {
    //   return props.isSelected ? 'white' : 'black'
    // },

    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    textAlign: 'center',
    width: 'inherit',
  },
  serverButtonContainer: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    overflowY: 'hidden',
  },
  serverButtonContainer__drawerClose: {
    alignContent: 'flex-start',
    // flexDirection: 'column',
    // height: 65,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  serverButtonWrapper: {
    margin: theme.spacing(1),
  },
  serverButtonAvatar: {
    width: '50% !important',
    paddingLeft: theme.spacing(1),
    backgroundColor: 'white !important',
    borderRadius: 10,
  },
  icon: {
    width: 18,
  },
  iconForService: {
    width: '100%',
    height: '100%',
    maxWidth: '60px',
    marginRight: 10,
  },
  iconOpen: {
    width: 100,
    marginLeft: '4rem',
    marginRight: '4rem',
  },
  iconClose: {
    width: 18,
    marginRight: 10,
  },
  buttonContentWrapper: {
    // padding: 5,
    fontFamily: 'system-ui',
    display: 'flex',
    alignItems: 'center',
  },
  loadingLayer: {
    backgroundColor: 'lightgrey',
    width: '100%',
    display: 'flex',
    height: '100%',
    opacity: 0.5,
    zIndex: theme.zIndex.drawer + 2,
    position: 'absolute',
  },
  loadingIcon: {
    zIndex: theme.zIndex.drawer + 3,
    position: 'absolute',
    right: '45%',
    bottom: '45%',
  },
  dialogButtonWrapper: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogButton: {
    fontWeight: 500,
    margin: theme.spacing(1),
    height: theme.spacing(4),
    paddingTop: theme.spacing(0.5),
    paddingButtons: theme.spacing(0.5),
  },
  changePasswordButtonRoot: {
    color: 'white',
    backgroundColor: orange[200],
    '&:hover': {
      backgroundColor: orange[500],
    },
  },
  dialogButtonText: {
    textTransform: 'none',
  },
}))
