import React from 'react'
import {
  TextField,
  makeStyles,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import { ErrorOutline, ArrowBack } from '@material-ui/icons'
import {
  resetPasswordMutation,
  validateTokenMutation,
} from '../utils/mutations'
import handleKey from '../utils/handleKey'

type ResetPasswordPagepProps = {
  setResetPasswordExpand: (value: boolean) => void
  setMessage: (value: string) => void
  isResetPassword: boolean
}

const ResetPasswordPage: React.FC<ResetPasswordPagepProps> = ({
  setResetPasswordExpand,
  setMessage,
  isResetPassword,
}) => {
  const classes = useStyles()
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isTokenValid, setIsTokenValid] = React.useState<boolean | null>(null)
  const searchString = window.location.search
  //token for reset password
  const token = searchString.substring(searchString.indexOf('=') + 1)
  //check token is valid
  React.useEffect(() => {
    if (isResetPassword) {
      validateTokenMutation(token).then(({ data }) => {
        setIsTokenValid(data?.userValidateToReset)
      })
    }
  }, [isResetPassword])

  const handleSubmit = () => {
    setIsSubmitting(true)
    resetPasswordMutation(password, token).then(({ data, errors }) => {
      if (errors) {
        setMessage(errors?.[0]?.message)
      } else {
        setMessage(data?.userResetPassword?.[0]?.message)
        setResetPasswordExpand(false)
        window.history.replaceState(null, 'login', '/')
      }
    })
  }
  const isPasswordMatch = password === confirmPassword
  const isError =
    !password || !confirmPassword || !isPasswordMatch || isSubmitting

  //listener for enter key
  React.useEffect(() => {
    const listener = handleKey(isResetPassword && !isError, handleSubmit)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [isResetPassword, isError])
  //

  if (isTokenValid === null)
    return (
      <div className={classes.loadingWrapper}>
        <CircularProgress className={classes.loading} />
      </div>
    )
  if (isTokenValid)
    return (
      <div>
        <div className={classes.titleWrapper}>
          <img
            src="https://companyresources.blob.core.windows.net/image/endata/icon-big.png"
            alt="logo"
            className={classes.logo}
          />
          <p className={classes.text}>Reset Password</p>
        </div>
        <div>
          <TextField
            variant="outlined"
            label="Password"
            type="password"
            inputProps={{
              autocomplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            fullWidth
            value={password}
            onChange={e => setPassword(e.target.value)}
            className={classes.textField}
            error={!isPasswordMatch}
          />
          <TextField
            variant="outlined"
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            inputProps={{
              autocomplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            fullWidth
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            className={classes.textField}
            error={!isPasswordMatch}
            helperText={!isPasswordMatch && 'New password not match'}
          />
        </div>
        <div className={classes.buttonWrapper}>
          <div />
          <div>
            <Button
              color="primary"
              variant="outlined"
              className={classes.button}
              disabled={isError}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    )
  return <ErrorPage setResetPasswordExpand={setResetPasswordExpand} />
}

export default ResetPasswordPage

const useStyles = makeStyles({
  textField: {
    marginBottom: '10px',
  },
  text: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    float: 'right',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  loadingWrapper: {
    textAlign: 'center',
    marginTop: 20,
  },
  loading: {
    width: '24px',
    height: '24px',
  },
  logo: {
    maxWidth: 'inHerit',
  },
})

type ErrorPageProps = {
  setResetPasswordExpand: (value: boolean) => void
}

const ErrorPage: React.FC<ErrorPageProps> = ({ setResetPasswordExpand }) => {
  const classes = useErrorPageStyles()
  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <div className={classes.title}>
          <ErrorOutline />
          An error has occurred
        </div>
        <IconButton
          className={classes.iconButton}
          onClick={() => {
            setResetPasswordExpand(false)
            window.history.replaceState(null, 'login', '/')
          }}
        >
          <ArrowBack />
        </IconButton>
      </div>
      <div className={classes.content}>
        Sorry, Your password reset link is invalid. You will need to use the
        forgotten password link on the login screen again to restart the
        process.
      </div>
    </div>
  )
}

const useErrorPageStyles = makeStyles({
  root: {
    height: 100,
    border: '2px solid red',
    borderRadius: 10,
    marginTop: 20,
  },
  titleWrapper: {
    fontSize: 18,
    backgroundColor: 'red',
    display: 'flex',
    padding: 2,
    color: 'white',
    justifyContent: 'space-between',
  },
  title: {
    display: 'flex',
  },
  content: {
    padding: 10,
  },
  iconButton: {
    borderRadius: '100%',
    padding: '0 12px',
    color: 'white',
    '&:hover': {
      borderRadius: 0,
    },
  },
})
