export default (condition: boolean, handleSubmit: any) => {
  const listener = (event: any) => {
    if (condition && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
      event.preventDefault()
      handleSubmit()
    }
  }
  document.addEventListener('keydown', listener)
  if (!condition) document.removeEventListener('keydown', listener)
  return listener
}
