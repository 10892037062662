import React from 'react'
import {
  makeStyles,
  Collapse,
  Snackbar,
  Grow,
  IconButton,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import ServerPage from './ServerPage'
import ResetPage from './ResetPage'
import LoginPage from './LoginPage'
import ResetPasswordPage from './ResetPasswordPage'

const Login = () => {
  const classes = useStyles()
  //link to reset-password page
  const isResetPassword = window.location.pathname.includes('reset-password')
  const [serverExpand, setServerExpand] = React.useState(false)
  const [resetExpand, setResetExpand] = React.useState(false)
  const [resetPasswordExpand, setResetPasswordExpand] =
    React.useState(isResetPassword)

  //set server login data
  const [data, setData] = React.useState<any>(null)
  const [message, setMessage] = React.useState('')
  const handleClose = () => setMessage('')
  const isShowLoginPage = !serverExpand && !resetExpand && !resetPasswordExpand

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <Collapse in={serverExpand}>
          <ServerPage
            data={data}
            setServerExpand={setServerExpand}
            setResetExpand={setResetExpand}
            setMessage={setMessage}
          />
        </Collapse>
        <Collapse in={resetExpand}>
          <ResetPage
            setResetExpand={setResetExpand}
            setMessage={setMessage}
            isResetPage={resetExpand}
            setServerExpand={setServerExpand}
            isAlreadyLogin={Boolean(data)}
          />
        </Collapse>
        <Collapse in={resetPasswordExpand}>
          <ResetPasswordPage
            setResetPasswordExpand={setResetPasswordExpand}
            setMessage={setMessage}
            isResetPassword={isResetPassword}
          />
        </Collapse>
        <Collapse in={isShowLoginPage}>
          <LoginPage
            setServerExpand={setServerExpand}
            setResetExpand={setResetExpand}
            isShowLoginPage={isShowLoginPage}
            setData={setData}
            data={data}
          />
        </Collapse>
        <Snackbar
          open={Boolean(message)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={() => setMessage('')}
          TransitionComponent={props => <Grow {...props} />}
          message={message}
          className={classes.snackbar}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
          autoHideDuration={null}
        />
      </div>
    </div>
  )
}
export default Login

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 100,
    width: '100%',
  },
  form: {
    width: '95%',
    maxWidth: '500px',
    position: 'relative',
  },
  snackbar: {
    whiteSpace: 'pre-wrap',
  },
})
