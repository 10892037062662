import React from 'react'
import { makeStyles, Chip, Theme, Avatar } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'

type ServerButtonProps = {
  serverInfo: any
  setServerExpand: (value: boolean) => void
  setResetExpand: (value: boolean) => void
  setExpand: (value: boolean) => void
  setSelectedServer: (value: any) => void
  setMessage: (value: string) => void
  selectedServer?: string | undefined
}
const ServerButton: React.FC<ServerButtonProps> = ({
  serverInfo,
  selectedServer,
  setSelectedServer,
  setExpand,
  setServerExpand,
  setResetExpand,
  setMessage,
}) => {
  const classes = useStyles({
    isSelected: selectedServer === serverInfo?.server,
  })
  const handleClick = () => {
    if (serverInfo.isValid) {
      setSelectedServer(serverInfo?.server)
      setExpand(false)
    } else {
      setServerExpand(false)
      setResetExpand(true)
      setTimeout(() => {
        setMessage(serverInfo.message)
      }, 100)
    }
  }

  return (
    <div className={classes.buttonWrapper}>
      <Chip
        classes={{
          label: classes.companyName,
          root: classes.root,
        }}
        avatar={
          <Avatar
            alt="company icon"
            src={serverInfo.icon}
            variant="square"
            className={classes.buttonAvatar}
            classes={{ img: classes.companyIcon }}
          />
        }
        deleteIcon={
          selectedServer === serverInfo?.server ? <DoneIcon /> : <div></div>
        }
        onDelete={handleClick}
        label={serverInfo.serverDisplayName}
        variant={selectedServer === serverInfo?.server ? 'default' : 'outlined'}
        onClick={handleClick}
      />
    </div>
  )
}

export default ServerButton
type Props = {
  isSelected: boolean
}
const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  serverWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  root: {
    '&&:hover': {
      backgroundColor: props => (props.isSelected ? '#e0e0e0' : '#cecece'),
      border: '2px solid #e4e4e4',
    },
    '&&:focus': {
      backgroundColor: '#cecece',
    },
    width: '100%',
    border: '2px solid #cecece',
    boxShadow: props =>
      props.isSelected
        ? 'inset #9c9c9c73 0px 0px 1px 1px'
        : '#00000000 0px 0px',
  },
  companyName: {
    fontSize: 14,
    color: props => (props.isSelected ? '#444343' : '#676A6C'),
    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    textAlign: 'center',
    width: 'inherit',
  },
  buttonWrapper: {
    margin: theme.spacing(1),
  },
  buttonAvatar: {
    width: '10px',
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    borderRadius: 10,
  },
  companyIcon: {
    objectFit: 'contain',
    maxWidth: 'inHerit',
  },
  // selectedItem: {
  //   backgroundColor: 'lightgrey !important',
  // },
}))
