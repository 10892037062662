import React from 'react'
import { makeStyles, Button, Icon } from '@material-ui/core'

type buttonType = 'button' | 'submit'
type ApplicationButtonProps = {
  applicationInfo: any
  isAutoSubmit?: boolean
  type: buttonType
  setIsSubmitting: (value: boolean) => void
  setRefreshToken?: (value: string) => void | null
  setToken?: (value: string) => void | null
  setExternalUrl?: (value: string) => void | null
  newLoginSupportedList?: Array<string>
  selectedServer?: string
}

const ApplicationButton: React.FC<ApplicationButtonProps> = ({
  isAutoSubmit = false,
  applicationInfo,
  type,
  setIsSubmitting,
  setRefreshToken,
  setToken,
  setExternalUrl,
  newLoginSupportedList,
  selectedServer,
}) => {
  const classes = useStyles()

  const handleClick = (e: any) => {
    setIsSubmitting(true)
    if (
      type === 'button' &&
      newLoginSupportedList &&
      selectedServer &&
      setRefreshToken &&
      setToken &&
      setExternalUrl
    ) {
      setRefreshToken(e.refreshToken)
      setToken(e.token)
      if (newLoginSupportedList.includes(selectedServer)) {
        setExternalUrl(`${e.url}/externalLogin`)
      } else {
        setExternalUrl(
          `${e?.url}/user/login?token=${e?.refreshToken}`
          //`http://localhost:3001/user/login?token=${e?.refreshToken}`
        )
      }
    }
  }

  const stringifiedApplicationInfo = JSON.stringify(applicationInfo)
  React.useEffect(() => {
    if (isAutoSubmit) {
      handleClick(applicationInfo)
    }
  }, [isAutoSubmit, stringifiedApplicationInfo, handleClick])

  if (isAutoSubmit) return <div></div>
  return (
    <Button
      className={classes?.applicationButton}
      variant="contained"
      color="primary"
      style={{
        backgroundColor: applicationInfo?.colour,
        textTransform: 'none',
      }}
      startIcon={
        <Icon className={classes?.buttonIcon}>
          {applicationInfo?.icon ?? 'explicit_outlined'}
        </Icon>
      }
      onClick={() => handleClick(applicationInfo)}
      type={type}
    >
      <span className={classes?.buttonText}>{applicationInfo.name}</span>
    </Button>
  )
}

export default ApplicationButton
const useStyles = makeStyles(theme => ({
  applicationButton: {
    width: 150,
    height: 43,
    marginBottom: theme.spacing(1.5),
    padding: theme.spacing(1),
  },
  buttonIcon: {
    fontSize: `${theme.spacing(2)}px !important`,
    paddingTop: `${theme.spacing(0.25)}px`,
  },
  buttonText: {
    fontSize: 12,
    fontFamily: 'system-ui',
  },
}))
