import React from 'react'
import {
  TextField,
  makeStyles,
  Button,
  CircularProgress,
} from '@material-ui/core'
import ReCAPTCHA from 'react-google-recaptcha'

import { loginMutation } from '../utils/mutations'
import handleKey from '../utils/handleKey'

type LoginPageProps = {
  setServerExpand: (value: boolean) => void
  setResetExpand: (value: boolean) => void
  setData: (value: any) => void
  data: any
  isShowLoginPage: boolean
}

const LoginPage: React.FC<LoginPageProps> = ({
  setServerExpand,
  setResetExpand,
  setData,
  data,
  isShowLoginPage,
}) => {
  const [account, setAccount] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  const [isCAPShow, setIsCAPShow] = React.useState(false)
  const [isCAPPass, setIsCAPPass] = React.useState(true)
  const [fastPortal, setFastPortal] = React.useState<any>()
  const [selectedServer, setSelectedServer] = React.useState<string>('')
  //create recap ref
  const recaptchaRef = React.createRef<any>()
  const dataString = JSON.stringify(data)
  const classes = useStyles()
  const newLoginSupportedList = ['SIT', 'UAT']

  const handleSubmit = () => {
    //if (!event) return null
    setIsSubmitting(true)
    loginMutation(account, password).then(({ data, errors }) => {
      if (errors) {
        //show recap check if login fail
        setIsError(true)
        setIsCAPShow(true)
        setIsCAPPass(false)
        //set cookie remember recap check status
        document.cookie =
          'loginSessionCheck=false; max-age=3600; SameSite=Strict; Secure'
        setIsSubmitting(false)
      } else {
        //when only one server/service jump to site directly
        if (
          data?.userPortalsAuthenticate?.length === 1 &&
          data?.userPortalsAuthenticate?.[0]?.applications?.length === 1
        ) {
          setFastPortal(data?.userPortalsAuthenticate?.[0]?.applications?.[0])
          setSelectedServer(data?.userPortalsAuthenticate?.[0]?.server)
        } else {
          //set server data
          setData(data?.userPortalsAuthenticate)
          setIsSubmitting(false)
        }
        //recap check pass
        document.cookie = 'loginSessionCheck=true; SameSite=Strict; Secure'
      }
    })
  }
  // ReCAPTCHA
  React.useEffect(() => {
    // get recap check status during initialization
    const defaultCAPPass = document?.cookie
      ?.split(';')
      ?.find(e => e.includes('loginSessionCheck'))
      ?.split('=')[1]
    if (defaultCAPPass === 'false') {
      setIsCAPShow(true)
      setIsCAPPass(false)
    }
  }, [])

  React.useEffect(() => {
    //reset recap when user pass check
    if (!isCAPPass) {
      recaptchaRef?.current?.reset()
    }
  }, [isCAPPass])

  // login success
  React.useEffect(() => {
    if (data) {
      setServerExpand(true)
    }
  }, [dataString])

  // fast portal for only one server/service
  React.useEffect(() => {
    if (fastPortal && selectedServer) {
      const data = JSON.stringify({
        token: fastPortal?.token,
        refreshToken: fastPortal?.refreshToken,
        firstRender: true,
      })
      const portal = document.getElementById('fastPortal')

      if (!newLoginSupportedList.includes(selectedServer)) {
        const externalUrl = `${fastPortal?.url}/user/login?token=${fastPortal?.refreshToken}`
        window.parent.location.href = externalUrl
      } else {
        window.onmessage = function (e: any) {
          const messageData = e?.data
          if (
            typeof messageData === 'string' &&
            messageData?.charAt(0) === '{' &&
            messageData?.charAt(messageData?.length - 1) === '}'
          ) {
            const result = JSON.parse(messageData?.replace(/\\/g, ''))
            if (result?.status === 'render') {
              //@ts-ignore
              portal.contentWindow.postMessage(data, '*')
            }
            if (result?.status === 'injected') {
              const initialRoute = `${fastPortal?.url}/${result?.initialRoute}`
              window.parent.location.href = initialRoute
            }
          }
        }
      }
    }
  }, [fastPortal, selectedServer])

  //listener for enter key
  React.useEffect(() => {
    if (isCAPPass) {
      const listener = handleKey(isShowLoginPage, handleSubmit)
      return () => {
        document.removeEventListener('keydown', listener)
      }
    }
  }, [isShowLoginPage, password, account, isCAPPass])

  return (
    <React.Fragment>
      <div className={classes.titleWrapper}>
        <p className={classes.text}>Welcome to ENData.</p>
        <img
          src="https://companyresources.blob.core.windows.net/image/endata/icon-big.png"
          alt="logo"
          className={classes.logo}
        />
      </div>
      <div className={classes.textFieldContainer}>
        <TextField
          variant="outlined"
          label="Username"
          name="account"
          fullWidth
          value={account}
          onChange={e => setAccount(e.target.value)}
          className={classes.textField}
        />
        <TextField
          variant="outlined"
          label="Password"
          name="password"
          type="password"
          fullWidth
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <small
          className={classes.resetPassword}
          onClick={() => setResetExpand(true)}
        >
          Change or forgot password?
        </small>
      </div>
      <div>
        {isError && (
          <div className={classes.errorMessage}>
            Your account information was entered incorrectly.
          </div>
        )}
        {isCAPShow && (
          <React.Fragment>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY as string}
              //@ts-ignore
              ref={recaptchaRef}
              onChange={e => {
                if (e) {
                  setIsCAPPass(true)
                } else {
                  setIsCAPPass(false)
                }
              }}
            />
            {!isCAPPass && (
              <div className={classes.errorMessage}>
                Please verify that you are not a robot
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          variant="outlined"
          fullWidth
          disabled={isSubmitting || !isCAPPass || !account || !password}
          className={classes.button}
          classes={{ label: classes.buttonText }}
          onClick={handleSubmit}
        >
          Login
        </Button>
        {isSubmitting && (
          <CircularProgress className={classes.loading} size={32} />
        )}
      </div>
      {fastPortal && (
        <iframe
          src={`${fastPortal?.url}/externalLogin`}
          id="fastPortal"
          width={0}
          height={0}
          style={{ visibility: 'hidden' }}
        />
      )}
    </React.Fragment>
  )
}

export default LoginPage

const useStyles = makeStyles({
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    maxWidth: 'inHerit',
  },
  text: {
    fontSize: '22px',
    maxWidth: '300px',
  },
  form: {
    width: '95%',
    maxWidth: '500px',
    position: 'relative',
  },
  textField: {
    marginBottom: '15px',
  },
  textFieldContainer: {
    marginBottom: '10px',
  },
  button: {
    marginTop: '20px',
  },
  buttonText: {
    textTransform: 'none',
  },
  errorMessage: {
    color: 'red',
  },
  buttonWrapper: {
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    left: '48%',
    bottom: 0,
  },
  resetPassword: {
    cursor: 'pointer',
    color: '#337ab7',
    maxWidth: 'fit-content',
    '&:hover': {
      color: '#42a1f5',
    },
  },
})
