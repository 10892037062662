const { REACT_APP_API_ENDPOINT } = process.env

const loginMutation = (acc: string, pss: string) => {
  const headers = {
    'Content-Type': 'application/json',
  }

  return fetch(`${REACT_APP_API_ENDPOINT}/graphql`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: `mutation {
        userPortalsAuthenticate (input: {
          userLogin: "${acc}",
          password: "${pss}"
        }) {
          userName
          serverDisplayName
          isValid
          message
          server
          logo
          icon
          applications {
            icon
            colour
            name
            url
            token
            refreshToken
            useLocalStorage
          }
        }
      }`,
    }),
  }).then(res => res.json())
}

const updatePasswordMutation = (acc: string, pss: string, newPss: string) => {
  const headers = {
    'Content-Type': 'application/json',
  }

  return fetch(`${REACT_APP_API_ENDPOINT}/graphql`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: `mutation {
        userUpdatePassword (input: {
          userLogin: "${acc}",
          password: "${pss}",
          newPassword: "${newPss}",
        }) {
          message
        }
      }`,
    }),
  }).then(async res => res.json())
}

const forgotPasswordMutation = (acc: string, email: string) => {
  const headers = {
    'Content-Type': 'application/json',
  }

  return fetch(`${REACT_APP_API_ENDPOINT}/graphql`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: `mutation {
        userForgotPassword (input: {
          userLogin: "${acc}",
          email: "${email}",
        })
      }`,
    }),
  }).then(async res => res.json())
}

const resetPasswordMutation = (pss: string, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${token}`,
  }

  return fetch(`${REACT_APP_API_ENDPOINT}/graphql`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: `mutation {
        userResetPassword (input: {
          newPassword: "${pss}",
        }) {
          message
        }
      }`,
    }),
  }).then(async res => res.json())
}

const validateTokenMutation = (token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${token}`,
  }
  return fetch(`${REACT_APP_API_ENDPOINT}/graphql`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: `mutation {
        userValidateToReset
      }`,
    }),
  }).then(async res => res.json())
}

export {
  loginMutation,
  updatePasswordMutation,
  forgotPasswordMutation,
  resetPasswordMutation,
  validateTokenMutation,
}
