import React from 'react'
import LoginPages from './pages'

function App() {
  return (
    <div className="App">
      <LoginPages />
    </div>
  )
}

export default App
